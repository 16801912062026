var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"IssueList"},[_c('IssueListAppBar',{staticClass:"IssueList__app-bar",attrs:{"project-id":_vm.projectId,"card-id":_vm.cardId,"sub-query":_vm.subQuery,"grouping-value":_vm.groupingValue,"selected-issues":_vm.selectedIssues}}),(_vm.issues)?_c('SplitLayout',{staticClass:"IssueList__split",style:({
      height: _vm.$vuetify.breakpoint.mdAndUp
        ? 'calc(100vh - 64px)'
        : 'calc(100vh - 56px)'
    }),attrs:{"sizes":[60, 40],"min-size":[300, 200],"hide-right":!_vm.inlineIssueId,"local-storage-key-prefix":"IssueList__split"},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('IssueTableView',{staticClass:"IssueList__issues",attrs:{"project-id":_vm.projectId,"issues":_vm.issues,"card-id":_vm.cardId,"open-issue-id":_vm.inlineIssueId},on:{"assign-from":function($event){return _vm.assignFromIssue($event.issue)},"mass-assign":_vm.assignFromSelection},model:{value:(_vm.selectedIssueIds),callback:function ($$v) {_vm.selectedIssueIds=$$v},expression:"selectedIssueIds"}})]},proxy:true},{key:"right",fn:function(){return [_c('v-navigation-drawer',{staticClass:"IssueList__issue-drawer",class:{ 'IssueList__issue-drawer--open': !!_vm.inlineIssueId },style:({
          transitionDuration: '200ms',
          transitionTimingFunction: 'ease-in-out',
          maxHeight: `calc(100vh - ${_vm.$vuetify.breakpoint.mdAndUp ? 64 : 56}px)`
        }),attrs:{"value":!!_vm.inlineIssueId,"width":"100%","stateless":"","right":""}},[(!!_vm.inlineIssueId)?_c('InlineIssue',{attrs:{"issue-id":_vm.inlineIssueId,"project-id":_vm.projectId}}):_vm._e()],1)]},proxy:true}],null,false,2653971390)}):_vm._e(),(_vm.projectId)?_c('AssigneeDialog',{attrs:{"project-id":_vm.projectId,"issues":_vm.assigneeDialogIssues},on:{"update:issues":function($event){$event.length === 0 && (_vm.assigneeDialog = false)}}}):_vm._e(),(_vm.selectedIssueIds.length > 0 &&
      (_vm.activeProjectIntegrations || []).length > 0 && !_vm.exportTasksDialogIsOpen)?_c('Portal',{attrs:{"to":"snackbar-action"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"IssueList__snackbar-task-btn px-2",attrs:{"text":"","tile":"","height":"40","color":hover || _vm.taskDropdownModel ? '#FFB950': '#69D2EF'}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-plus-circle-outline')}}),_c('span',{staticClass:"ml-2",domProps:{"textContent":_vm._s(_vm.$t('issue.Task'))}})],1)]}}],null,true)})]}}],null,false,2272799401),model:{value:(_vm.taskDropdownModel),callback:function ($$v) {_vm.taskDropdownModel=$$v},expression:"taskDropdownModel"}},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.activeProjectIntegrations),function(integration){return _c('v-list-item',{key:integration.id,staticClass:"pl-2",staticStyle:{"height":"36px"},attrs:{"ripple":{ class: 'app-ripple' }},on:{"click":function($event){return _vm.createTasks({ integration })}}},[_c('v-list-item-icon',{staticClass:"mr-1"},[_c('v-icon',{domProps:{"textContent":_vm._s(integration.integrationType.assets.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(integration.name)}})],1)],1)}),1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }