<template>
  <v-menu
    v-model="dropdownModel"
    :disabled="exportRecords.length <= 1"
    absolute
    left
    :max-width="260"
  >
    <!--    :attach="attachToParent ? ($parent && $parent.$el) : false"-->
    <template #activator="{ on }"><!-- eslint-disable-line -->
      <v-btn
        class="IssueTaskButton"
        :class="{
          'IssueTaskButton--has-records': exportRecords.length > 0,
          [activatorClass]: !!activatorClass,
        }"
        text
        tile
        height="unset"
        :ripple="{ class: 'white--text' }"
        :href="exportRecords.length === 1 ? exportRecords[0].url : undefined"
        target="_blank"
        rel="noopener noreferrer nofollow"
        v-bind="$attrs"
        v-on="on"
        @mousedown.stop
        @touchstart.stop
        @click="onClick"
      >
        <template v-if="exportRecords.length">
          <v-icon
            key="specific-icon"
            v-text="exportRecords[0].integration.assets.icon"
          />
          <span
            key="specific-label"
            class="ml-2 text-truncate"
            v-text="exportRecords[0].metaData.jiraIssueKey"
          />
        </template>

        <template v-else>
          <v-icon
            key="fallback-icon"
            v-text="'mdi-widgets-outline'"
          />
          <span
            key="fallback-label"
            class="ml-2"
            v-text="$t('issue.Task')"
          />
        </template>
      </v-btn>
    </template>

    <v-list
      v-if="exportRecords.length > 1"
      dense
      @mousedown.native.stop
      @touchstart.native.stop
    >
      <v-list-item
        v-for="record in exportRecords"
        :key="record.key"
        class="pl-2"
        :ripple="{ class: 'app-ripple' }"
        style="height: 36px"
        :href="record.url"
        target="_blank"
        rel="noopener noreferrer nofollow"
        @click.stop
      >
        <v-list-item-icon class="mr-1">
          <v-icon v-text="record.integration.assets.icon" />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="record.displayName" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { JIRA, INTEGRATION } from '../constants'

export default {
  name: 'IssueTaskButton',

  props: {
    projectId: { type: String, required: true },
    issue: { type: Object, required: true },
    activatorClass: { type: String, default: '' },
    attachToParent: { type: Boolean, default: false },
  },

  data() {
    return {
      dropdownModel: false,
    }
  },

  computed: {
    exportRecords() {
      const { exportRecords } = this.issue
      return (exportRecords || [])
        .map((rec, i) => {
          if (rec.integrationType === JIRA) {
            return {
              ...rec,
              displayName: `[${rec.metaData.jiraIssueKey}] ${rec.metaData.jiraIssueName}`,
              url: rec.metaData.jiraIssueUrl,
              key: `${rec.metaData.jiraIssueUrl}-${i}`,
              integration: INTEGRATION[rec.integrationType],
            }
          }

          console.warn('new integration type: ' + rec.integrationType, rec)
          return {
            ...rec,
            displayName: this.$t('issue.unrecognizedRecord'),
            url: undefined,
            key: i,
            integration: INTEGRATION[rec.integrationType],
          }
        })
    },
  },

  methods: {
    onClick(event) {
      const { exportRecords } = this
      const n = exportRecords.length
      if (n === 1) {
        // do not prevent default: open the first url
        event.stopPropagation()
      } else if (n === 0) {
        // do nothing: no records
        // propagate click so that it can open side drawer
        // event.preventDefault()
      } else {
        // open dropdown with all `exportRecords`
        event.preventDefault()
        event.stopPropagation()
        this.dropdownModel = !this.dropdownModel
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/variables'

.IssueTaskButton
  align-self: stretch
  opacity: 0
  transition: all .15s map-get($transition, 'swing')
  padding: 0 16px !important
  overflow: hidden
  justify-content: flex-start !important

  ::v-deep .v-btn__content
    overflow: hidden
    text-overflow: ellipsis
    text-align: left
    max-width: 100%

  &--has-records
    opacity: 1 !important

    &:not(.v-btn--disabled)
      &:hover, &:active, &:focus
        opacity: .3
</style>
