<template>
  <div
    v-if="issue != null"
    class="InlineIssue"
  >
    <div
      ref="fixedHeader"
      class="InlineIssue__fixed-header"
    >
      <div class="InlineIssue__header-title-row">
        <router-link
          class="InlineIssue__title"
          :to="detailsRoute"
          v-text="issue && issue.name"
        />

        <div
          style="flex: 0 0 24px"
          class="d-flex align-center justify-center ml-auto"
        >
          <v-btn
            icon
            :to="closeRoute"
            replace
            exact
            :ripple="{ class: 'app-ripple' }"
          >
            <v-icon>$close</v-icon>
          </v-btn>
        </div>
      </div>

      <CommonClassicTabs
        :key="`tabs-${issue.id}`"
        v-model="currentTab"
        class="InlineIssue__tabs"
        :tabs="tabs"
        small
      />
    </div>

    <v-tabs-items
      :key="`tabs-items-${issue.id}`"
      v-model="currentTab"
      class="InlineIssue__tab-items"
    >
      <!-- details/description tab content -->
      <v-tab-item
        :key="TAB.info.value"
        :value="TAB.info.value"
        class="InlineIssue__tab-item"
      >
        <v-card
          :key="issueId"
          flat
          tile
          class="InlineIssue__card InlineIssue__info"
          :style="cardStyle"
        >
          <InlineIssueSummary
            ref="summary"
            class="InlineIssue__summary"
            :issue="issue"
            :project-id="issue.projectID"
          />

          <div class="InlineIssue__toc">
            <a
              v-for="tocItem in toc"
              :key="tocItem.href"
              :href="tocItem.href"
              class="InlineIssue__toc-item"
              v-text="tocItem.text"
            />
          </div>

          <h2
            v-if="(issue.exportRecords && issue.exportRecords.length) || (integrations && integrations.length)"
            class="InlineIssue__subtitle d-flex align-center"
          >
            {{ $t('issue.Tasks') }}

            <v-menu
              v-if="integrations && integrations.length"
              offset-y
              left
            >
              <template #activator="{ on, attrs }">
                <CommonIconButton
                  class="ml-auto"
                  color="primary"
                  icon="mdi-plus-circle-outline"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>

              <v-list
                dense
                class="py-0"
              >
                <v-subheader
                  class="px-3"
                  v-text="$t('integration.SelectIntegration')"
                />
                <v-list-item
                  v-for="integration in integrations"
                  :key="integration.id"
                  :ripple="{ class: 'app-ripple' }"
                  class="px-3"
                  @click="openExportDialog(integration.id)"
                >
                  {{ integration.name }}
                </v-list-item>
              </v-list>
            </v-menu>
          </h2>
          <IssueExportRecords
            v-if="issue.exportRecords && issue.exportRecords.length"
            class="InlineIssue__export-records"
            :records="issue.exportRecords"
          />

          <template
            v-if="fieldsLookup &&
              fieldsLookup.assets &&
              issueSchema &&
              issueSchema.assetVersion === ASSET_VERSION.COMPLEX"
          >
            <h2
              id="issue-assets"
              class="InlineIssue__subtitle"
              v-text="getFieldsetDisplayName(fieldsLookup.assets) || $t('issue.AffectedAssets')"
            />

            <div
              v-for="(asset, ix) in issue.data.assets"
              :key="ix"
              class="InlineIssue__assets"
            >
              <div class="InlineIssue__asset mt-2 mb-2">
                <div v-if="asset.ip">
                  <b>IP</b> <span class="ml-1">{{ asset.ip }}</span>
                </div>
                <div
                  v-if="asset.hostname"
                  :class="{ 'ml-2': asset.ip }"
                >
                  <b>Hostname</b> <span class="ml-1">{{ asset.hostname }}</span>
                </div>
                <div
                  v-if="asset.port"
                  :class="{ 'ml-2': asset.ip || asset.hostname }"
                >
                  <b>Port</b> <span class="ml-1">{{ asset.port }}</span>
                </div>
              </div>
              <div
                v-if="asset.protocol"
                class="mt-2 mb-2"
              >
                <b>Protocol</b> {{ asset.protocol }}
              </div>
              <div
                v-if="asset.path"
                class="mt-2 mb-2"
              >
                <b>Path</b> {{ asset.path }}
              </div>
              <div
                v-if="asset.optional"
                class="mt-2 mb-2"
              >
                <b>Extra info</b> {{ asset.optional }}
              </div>
            </div>
          </template>

          <template v-if="fieldsLookup && (fieldsLookup.ips || fieldsLookup.hostnames) && (ips.length || hostnames.length)">
            <h2
              id="issue-assets"
              class="InlineIssue__subtitle"
              v-text="getFieldsetDisplayName(fieldsLookup.ips || fieldsLookup.hostnames) || $t('issue.AffectedAssets')"
            />
            <section class="d-flex">
              <div
                v-if="fieldsLookup.ips"
                :style="{
                  flex: fieldsLookup.hostnames ? '0 0 140px' : '1',
                }"
              >
                <div
                  v-for="(ip, i) in ips"
                  :key="i"
                  v-text="ip"
                />
              </div>
              <div
                v-if="fieldsLookup.hostnames"
                class="flex-grow-1"
              >
                <div
                  v-for="(hostname, i) in hostnames"
                  :key="i"
                  v-text="hostname"
                />
              </div>
            </section>
          </template>

          <template v-if="issue">
            <IssueField
              v-for="field in dynamicFields"
              :id="'issue-' + field.name"
              :key="field.name"
              :field="field"
              :issue="issue"
              inline
              class="InlineIssue__field"
            />
          </template>

          <template v-if="files && files.length > 0">
            <h2
              id="issue-attachments"
              class="InlineIssue__subtitle"
              v-text="$t('issue.Attachments')"
            />
            <section
              class="InlineIssue__attachments"
            >
              <div
                v-for="file in files"
                :key="file.uuid"
              >
                <a
                  class="InlineIssue__file"
                  @click.prevent="galleryFileUuid = file.uuid"
                >
                  {{ file.fileName }}
                </a>
              </div>
              <v-btn
                v-if="files && files.length"
                outlined
                color="primary"
                class="mt-4"
                @click="downloadAll()"
              >
                {{ $t('issue.DownloadAll') }}
              </v-btn>
            </section>
          </template>

          <IssueStatusHistory
            class="InlineIssue__status-history"
            :status-history="issue && issue.statusHistory"
            inline-styles
            platform-name="frigate"
            :project-id="issue.projectID"
          />
        </v-card>
      </v-tab-item>

      <!-- chat tab content -->
      <v-tab-item
        :key="TAB.chat.value"
        :value="TAB.chat.value"
        class="InlineIssue__tab-item"
      >
        <IssueChat
          :key="issueId"
          :issue-uuid="issueId"
          class="InlineIssue__card InlineIssue__chat"
          :style="cardStyle"
        />
      </v-tab-item>

      <!-- comments tab content -->
      <v-tab-item
        v-if="showCommentsTab"
        :key="TAB.comments.value"
        :value="TAB.comments.value"
        class="InlineIssue__tab-item"
      >
        <IssueComments
          :key="issueId"
          :issue-uuid="issueId"
          class="InlineIssue__card InlineIssue__comments"
          :style="cardStyle"
        />
      </v-tab-item>
    </v-tabs-items>

    <GalleryDialog
      v-model="galleryFileUuid"
      :is-open.sync="galleryIsOpen"
      :files="files"
    />
  </div>
</template>

<script>
import * as R from 'ramda'
import { IssueStatusHistory } from '@hexway/shared-front'
import i18n from '../i18n'

import { axiosInstance as axios } from '../api'
import bus from '../bus'
import { ISSUE_CUSTOM_WIDGET_FIELDS, ISSUE_FIELD_TYPE, ASSET_VERSION } from '../constants'
import { handleError, issueRouteFromIssueList } from '../helpers'

import Dialog from '../store/orm/dialog'
import Issue from '../store/orm/issue'
import IssueStatusChange from '../store/orm/issueStatusChange'

import keyboardNavigation from '../mixins/keyboardNavigation'

import GalleryDialog from './GalleryDialog'
import InlineIssueSummary from './InlineIssueSummary'
import IssueChat from './IssueChat'
import IssueComments from './IssueComments'
import IssueField from './IssueField'
import IssueExportRecords from './IssueExportRecords'

const TAB = Object.freeze({
  info: Object.freeze({ value: 'info-tab', label: i18n.t('issue.Info') }),
  chat: Object.freeze({ value: 'chat-tab', label: i18n.t('issue.Messages') }),
  comments: Object.freeze({ value: 'comments-tab', label: i18n.t('issue.Comments') }),
})

export default {
  name: 'InlineIssue',

  components: {
    GalleryDialog,
    InlineIssueSummary,
    IssueChat,
    IssueComments,
    IssueField,
    IssueExportRecords,
    IssueStatusHistory,
  },

  mixins: [
    keyboardNavigation({
      preventDefault: true,

      props: {
        leftRoute: 'previousRoute',
        rightRoute: 'nextRoute',
        upRoute: 'previousRoute',
        downRoute: 'nextRoute',
        homeRoute: 'firstIssueRoute',
        endRoute: 'lastIssueRoute',
      },
      methods: { done: 'broadcastNavigation' },
    }),
  ],

  props: {
    projectId: { type: String, default: null },
    issueId: { type: String, required: true },
  },

  data: () => ({
    ASSET_VERSION,
    TAB,
    headerHeight: 122,
    currentTab: TAB.info.value,

    galleryFileUuid: null,
  }),

  computed: {
    issue() {
      return Issue.query()
        .with('project.group')
        .with('exportRecords')
        .with('statusHistory.comments')
        .find(this.issueId)
    },

    integrations() {
      const { $store, issue } = this
      return issue && $store.getters['integration/forProject'](issue.projectID)
    },

    integrationsWithComments() {
      const { integrations } = this
      return integrations && integrations.filter(integration => integration.syncCommentsEnabled)
    },

    issues() {
      const { projectId } = this
      const q = Issue.query()
      return projectId
        ? q.where('projectID', projectId).all()
        : q.all()
    },

    listOrder() { return this.$store.state.entities.issue.$visibleOrder },

    issueOrder() {
      const { issueId, issues, listOrder } = this
      return listOrder.includes(issueId)
        ? listOrder
        : (issues && issues.map(R.prop('id')))
    },

    fields() {
      const { $store, issue } = this
      return issue?.projectID && $store.getters['issueSchema/fields'](issue.projectID)
    },

    fieldsLookup() {
      const { $store, issue } = this
      return issue?.projectID && $store.getters['issueSchema/fieldsLookup'](issue.projectID)
    },

    issueSchema() { return this.$store.getters['issueSchema/get'](this.projectId) || null },

    dynamicFields() {
      const { fields } = this
      return fields && fields
        .filter(f => !ISSUE_CUSTOM_WIDGET_FIELDS.includes(f.name))
    },

    currentPosition() { // Maybe<int>
      const { issueId, issueOrder } = this

      if (!issueOrder) return null
      const pos = issueOrder.indexOf(issueId)
      return pos === -1 ? null : pos
    },

    detailsRoute() {
      const {
        projectId,
        $route: { query },
        cardId,
        issueId,
      } = this
      return issueRouteFromIssueList({ query, projectId, cardId, issueId })
    },

    closeRoute() {
      return R.pipe(
        R.dissocPath(['query', 'issueId']),
        R.pick(['name', 'params', 'query']),
      )(this.$route)
    },

    occupiedVerticalSpace() {
      const { headerHeight } = this
      const { smAndDown } = this.$vuetify.breakpoint

      const appBarHeight = smAndDown ? 56 : 64
      return appBarHeight + headerHeight
    },

    cardStyle() {
      const { occupiedVerticalSpace } = this
      return {
        minHeight: `calc(100vh - ${occupiedVerticalSpace}px)`,
        maxHeight: `calc(100vh - ${occupiedVerticalSpace}px)`,
      }
    },

    previousRoute() {
      const { $route, issueOrder, currentPosition: ix } = this
      if (!issueOrder || !ix || ix <= 0) return null

      const previousIssueId = issueOrder[ix - 1]
      return R.pipe(
        R.assocPath(['query', 'issueId'], previousIssueId),
        R.pick(['name', 'params', 'query']),
      )($route)
    },

    nextRoute() {
      const { $route, issueOrder, currentPosition: ix } = this
      if (!issueOrder || ix === null || ix >= (issueOrder.length - 1)) return null

      const nextIssueId = issueOrder[ix + 1]
      return R.pipe(
        R.assocPath(['query', 'issueId'], nextIssueId),
        R.pick(['name', 'params', 'query']),
      )($route)
    },

    firstIssueRoute() {
      const { $route, issueOrder } = this
      if (!issueOrder || !issueOrder.length) return null

      return R.pipe(
        R.assocPath(['query', 'issueId'], issueOrder[0]),
        R.pick(['name', 'params', 'query']),
      )($route)
    },

    lastIssueRoute() {
      const { $route, issueOrder } = this
      if (!issueOrder || !issueOrder.length) return null

      return R.pipe(
        R.assocPath(['query', 'issueId'], R.last(issueOrder)),
        R.pick(['name', 'params', 'query']),
      )($route)
    },

    ips() { return this.issue?.ips || [] },
    hostnames() { return this.issue?.hostnames || [] },
    files() { return this.issue?.attachments || [] },

    galleryIsOpen: {
      get() { return this.galleryFileUuid != null },
      set(isOpen) {
        this.galleryFileUuid = isOpen
          ? this.files[0]?.uuid ?? null
          : null
      },
    },

    toc() {
      const { dynamicFields, issue, files } = this
      if (!issue || !dynamicFields) return null

      const getValue = (fieldName) =>
        Issue.hardcodedFields.includes(fieldName)
          ? issue[fieldName]
          : issue.data[fieldName]

      const isEmptyValue = (field, value) =>
        [
          ISSUE_FIELD_TYPE.INTEGER,
          ISSUE_FIELD_TYPE.FLOAT,
          ISSUE_FIELD_TYPE.BOOLEAN,
        ].includes(field.type)
          ? value == null
          : !value

      const isEmptyField = field =>
        field.isList
          ? !getValue(field.name)?.length
          : isEmptyValue(field, getValue(field.name))

      return R.pipe(
        R.map(field => !isEmptyField(field) && {
          text: field.displayName,
          href: `#issue-${field.name}`,
        }),
        R.filter(Boolean),
        R.when(
          () => files.length,
          R.append({ text: this.$t('issue.Attachments'), href: '#issue-attachments' }),
        ),
      )(dynamicFields)
    },

    showCommentsTab() {
      const { issue, integrationsWithComments } = this

      const integrationWithCommentsIds = integrationsWithComments &&
        new Set(integrationsWithComments.map(R.prop('id')))

      return issue && integrationWithCommentsIds &&
        issue.exportRecords
          .filter(rec => integrationWithCommentsIds.has(rec.integrationID))
          .length > 0
    },

    tabs() {
      const { showCommentsTab } = this
      return [
        TAB.info,
        TAB.chat,
        showCommentsTab && TAB.comments,
      ].filter(Boolean)
    },
  },

  watch: {
    issueId: {
      async handler() {
        await Promise.all([
          this.maybeFetchDetails(),
          this.fetchStatusHistory(),
        ])
      },
      immediate: true,
    },

    'issue.projectID': {
      async handler(issueProjectId) {
        if (issueProjectId && !this.projectId) {
          await Promise.all([
            this.$store.dispatch('issueSchema/get', {
              projectId: issueProjectId,
              reload: false,
            }),
            this.$store.dispatch('integration/getForProject', {
              projectId: issueProjectId,
              reload: false,
            }),
          ])
        }
      },
      immediate: true,
    },
  },

  async updated() {
    await this.$nextTick()
    const headerEl = this.$refs.fixedHeader
    if (!headerEl) return
    const newHeaderHeight = headerEl.getBoundingClientRect().height
    if (newHeaderHeight === this.headerHeight) return
    this.headerHeight = newHeaderHeight
  },

  methods: {
    async maybeFetchDetails() {
      const { issueId } = this
      await Issue.dispatch('$getDetails', { issueId })
    },

    fetchStatusHistory() {
      const { issueId } = this
      return IssueStatusChange.dispatch('$get', { issueId })
    },

    broadcastNavigation() {
      // required to adjust scroll position of the table/list
      // see `IssueListView`, `IssueTableView` components
      bus.$emit('inlineIssue:navigation')
    },

    downloadAll() {
      const { $store, files } = this
      Promise.all(files.map(file =>
        axios.head(file.url, { baseURL: null })
          .then(() => {
            const a = document.createElement('a')
            a.href = file.url
            a.download = ''
            a.click()
          }),
      ))
        .catch(e => handleError($store, e).catch(() => {}))
    },

    getFieldsetDisplayName(field) {
      return field.metadata?.fieldsetDisplayName
    },

    openExportDialog(integrationId) {
      if (!this.issue && !this.projectId) {
        throw new Error('Issue must be loaded')
      }
      Dialog.open({
        componentName: 'ExportTasksDialog',
        props: {
          integrationId,
          projectId: this.projectId || this.issue.projectID,
          issueIds: [this.issueId],
        },
      })
    },
  },
}
</script>

<style lang="sass" scoped>
  @import '../scss/mixins'

  .InlineIssue
    height: 100%
    display: flex
    flex-direction: column
    justify-content: stretch
    overflow-y: hidden

    & b
      font-weight: 500

    &__fixed-header
      background: #F5F5F9
      padding-top: 24px
      padding-left: 28px
      padding-right: 16px
      border-bottom: 2px solid #e6e6f2

    &__header-title-row
      display: flex
      align-items: center

      padding-bottom: 12px

    &__asset
      display: flex

    &__title
      display: block
      color: inherit
      text-decoration: none
      font-weight: 500
      font-size: 24px
      line-height: 44px
      letter-spacing: 0.005em
      max-height: 32px * 4
      overflow: hidden auto
      text-overflow: ellipsis
      flex: 0 1 auto

      &:hover, &:active, &:focus
        color: #1066E6
        text-decoration: underline

    &__actions
      margin-left: auto
      padding-right: 4px

    &__tab-items
      display: flex
      flex-direction: column
      flex-wrap: nowrap
      height: 100%
      color: #3C3A52

    &__btn
      color: #8B90A0
      background: white
      margin-left: auto

    &__card
      overflow-x: hidden
      overflow-y: auto
      height: 100%

    &__comments
      overflow: hidden

    &__info
      padding: 0 32px 32px

    &__summary
      margin: 0 -31px
      padding: 32px 32px 16px

    &__export-records
      margin: 4px 0 12px

    &__toc
      font-size: 14px
      line-height: 24px
      font-weight: 400
      margin: (18px - 2px) -8px
      text-overflow: ellipsis
      overflow: hidden

    &__toc-item
      display: inline
      text-decoration: underline dotted
      margin: 2px 8px
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
      &:hover, &:active, &:focus
        text-decoration: underline
      &:last-child
        margin-right: 0

    &__subtitle
      font-style: normal
      font-weight: 500
      font-size: 16px
      line-height: 24px
      margin-top: 18px
      margin-bottom: 4px
      letter-spacing: 0.005em

    &__paragraph
      margin-top: 8px
      margin-bottom: 13px
      font-size: 14px
      line-height: 20px

    // &__attachments
      // padding-top: 24px

    &__file
      color: #3C3A52
      font-size: 14px
      line-height: 20px
      text-decoration: none

      &:hover, &:active, &:focus
        color: #1066E6
</style>
