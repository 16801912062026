<template>
  <div
    v-if="issue != null"
    class="IssueComments"
  >
    <v-tabs
      v-if="integrationsWithComments != null && integrationsWithComments.length > 1"
      v-model="integrationId"
      class="IssueComments__tabs"
    >
      <!-- href is fucked, but there's no better workaround https://github.com/vuetifyjs/vuetify/issues/10540 -->
      <v-tab
        v-for="integration in integrationsWithComments"
        :key="integration.id"
        :href="'#' + integration.id"
      >
        {{ integration.name }}
      </v-tab>
    </v-tabs>

    <div
      v-if="exportRecordId != null"
      class="IssueComments__content"
    >
      <div
        v-if="integrationExportRecords && integrationExportRecords.length > 1"
        v-ripple="{ class: 'app-ripple' }"
        class="IssueComments__record-toolbar"
        role="button"
        tabindex="0"
        @click="exportRecordId = null"
      >
        <v-icon
          class="mr-4"
          v-text="'mdi-arrow-left'"
        />
        <span
          class="IssueComments__title"
          v-text="exportRecord.metaData.jiraIssueKey"
        />
        <span class="d-flex align-center ml-auto">
          <span
            class="IssueComments__caption"
            v-text="$t('issue.Created')"
          />
          <span
            class="IssueComments__text"
            v-text="fmtDt(exportRecord.created)"
          />
        </span>
      </div>

      <IssueCommentsMessages
        v-if="comments && comments.length"
        :comments="comments"
        class="IssueComments__messages"
        @edit="startEdit($event.comment)"
      />
      <EmptyState
        v-else
        :img-src="require('@/assets/images/empty-abstract-grey.svg')"
        :img-width="190"
        :img-height="140"
        :title="$t('issue.NoCommentsM')"
        secondary
      />

      <div
        v-if="pollError"
        class="IssueComments__error"
      >
        <b v-text="$t('issue.ConnectionError')" />
        <v-btn
          text
          tile
          color="white"
          :loading="pollTimeoutId != null"
          :disabled="pollTimeoutId != null"
          @click="startPolling()"
        >
          {{ $t('issue.Retry') }}
        </v-btn>
      </div>

      <ChatPrompt
        ref="chatPrompt"
        v-model="promptModel"
        class="IssueComments__prompt"
        :clearable="editCommentForm.id != null"
        @submit="editCommentForm.id
          ? submitEdit($event.value, $event.files)
          : submitNewMessage($event.value, $event.files)"
        @clear="abortEdit"
      />
    </div>
    <div
      v-else-if="integrationExportRecords && integrationExportRecords.length > 0"
      class="IssueComments__content IssueComments__record-selector"
    >
      <v-sheet
        v-for="exportRecord in integrationExportRecords"
        :key="exportRecord.id"
        v-ripple="{ class: 'app-ripple' }"
        color="#FAFAFA"
        class="IssueComments__record-card"
        tabindex="0"
        role="button"
        @click="exportRecordId = exportRecord.id"
        @keyup.enter="exportRecordId = exportRecord.id"
        @keyup.space="exportRecordId = exportRecord.id"
      >
        <span
          class="IssueComments__title"
          v-text="exportRecord.metaData.jiraIssueKey"
        />
        <span class="d-flex align-center ml-auto">
          <span
            class="IssueComments__caption"
            v-text="$t('issue.Created')"
          />
          <span
            class="IssueComments__text"
            v-text="fmtDt(exportRecord.created)"
          />
        </span>
      </v-sheet>
    </div>
    <div v-else-if="issue != null && integrations != null">
      1. No export records
      2. Some error here????
    </div>
  </div>
</template>

<script>
import { CHAT_POLL_INTERVAL } from '../constants'
import { fmtDt } from '../helpers'

import Issue from '../store/orm/issue'
import IssueComment from '../store/orm/issueComment'
import IssueExportRecord from '../store/orm/issueExportRecord'

import IssueCommentsMessages from './IssueCommentsMessages'
import ChatPrompt from './ChatPrompt'
import EmptyState from './EmptyState'

export default {
  name: 'IssueComments',

  components: {
    EmptyState,
    ChatPrompt,
    IssueCommentsMessages,
  },

  props: {
    issueUuid: { type: String, required: true },
  },

  data() {
    return {
      newMessage: { text: '' },
      pollTimeoutId: null,
      pollError: false,
      exportRecordId: null,
      integrationId: null,
      loadingComments: false,
      editCommentForm: {
        id: null,
        originalText: '',
        newText: '',
      },
    }
  },

  computed: {
    promptModel: {
      get() {
        return this.editCommentForm.id
          ? this.editCommentForm.newText
          : this.newMessage.text
      },
      set(text) {
        if (this.editCommentForm.id) {
          this.editCommentForm.newText = text
        } else {
          this.newMessage.text = text
        }
      },
    },

    issue() {
      const { issueUuid } = this
      return Issue.query().withAll().find(issueUuid)
    },

    integrations() {
      const { $store, issue } = this
      return issue && $store.getters['integration/forProject'](issue.projectID)
    },

    exportRecord() {
      const { exportRecordId } = this
      return exportRecordId && IssueExportRecord.query().withAll().find(exportRecordId)
    },

    integration() {
      const { $store, integrationId } = this
      return integrationId && $store.getters['integration/get'](integrationId)
    },

    integrationExportRecords() {
      const { integrationId, issueUuid } = this
      return IssueExportRecord
        .query()
        .withAll()
        .where('integrationID', integrationId)
        .where('issueId', issueUuid)
        .orderBy('created', 'desc')
        .all()
    },

    integrationsWithExportRecords() {
      const { integrations, issue } = this
      if (!integrations || !issue) return null
      const keepIntegrationIds = new Set(issue.exportRecords.map(rec => rec.integrationID))
      return integrations.filter(integration => keepIntegrationIds.has(integration.id))
    },

    integrationsWithComments() {
      const { integrationsWithExportRecords } = this
      return integrationsWithExportRecords && integrationsWithExportRecords
        .filter(ig => ig.syncCommentsEnabled)
    },

    comments() {
      const { exportRecordId } = this
      return exportRecordId &&
        IssueComment
          .query()
          .with('comments')
          .with('exportRecord')
          .orderBy('created')
          .where('exportRecordID', exportRecordId)
          .all()
    },
  },

  watch: {
    exportRecordId: {
      immediate: true,
      handler(recId) {
        if (recId) this.startPolling()
        else this.stopPolling()
      },
    },

    integrationsWithComments: {
      immediate: true,
      handler(integrations) {
        const integrationIds = integrations ? integrations.map(ig => ig.id) : []
        if (integrations.length && !integrationIds.includes(this.integrationId)) {
          this.integrationId = integrations?.length ? integrations[0].id : null
        }
      },
    },

    integrationExportRecords: {
      immediate: true,
      handler(exportRecords, prevExportRecords) {
        const newIds = exportRecords?.map?.(rec => rec.id)?.join?.(',')
        const prevIds = prevExportRecords?.map?.(rec => rec.id)?.join?.(',')
        if (newIds === prevIds) return
        this.exportRecordId =
          exportRecords?.length === 1 ? exportRecords[0].id : null
      },
    },

    // 'issue.exportRecords': {
    //   immediate: true,
    //   handler(exportRecords) {
    //     if (exportRecords?.length === 1) this.exportRecordId = exportRecords[0].id
    //   },
    // },

    integrations: {
      immediate: true,
      handler(integrations) {
        if (integrations == null) this.fetchIntegrations()
      },
    },
  },

  beforeDestroy() {
    this.stopPolling()
  },

  methods: {
    fmtDt,

    fetchIntegrations() {
      const { $store, issue } = this
      if (!this.issue) throw new Error("Issue isn't loaded")
      return $store.dispatch('integration/getForProject', { projectId: issue.projectID })
    },

    fetchComments(setLoading = true) {
      const { exportRecordId } = this
      if (!exportRecordId) throw new Error('No selected export record id')
      if (setLoading) this.loadingComments = true
      return IssueComment
        .dispatch('$getForExportRecord', { exportRecordId, reload: true })
        .finally(() => { this.loadingComments = false })
    },

    async fetchCommentsTick() {
      try {
        await this.fetchComments(false)
        this.pollError = false
        if (this.pollTimeoutId) {
          this.pollTimeoutId = setTimeout(this.fetchCommentsTick, CHAT_POLL_INTERVAL)
        }
      } catch (e) {
        this.pollError = true
      } finally {
        this.pollTimeoutId = null
      }
    },

    stopPolling() {
      clearTimeout(this.pollTimeoutId)
      this.pollTimeoutId = null
    },

    startPolling() {
      this.stopPolling()
      this.fetchComments()
      this.pollTimeoutId = setTimeout(this.fetchCommentsTick, CHAT_POLL_INTERVAL)
    },

    submitNewMessage(text, files) {
      const { exportRecordId } = this

      if (!exportRecordId) throw new Error('No selected export record')
      if (
        files.length &&
        !confirm(this.$t('issue.FileUploadNotImplementedSendQ'))
      ) return Promise.resolve(undefined)

      // reset chat form
      this.newMessage.text = ''
      this.$refs.chatPrompt?.resetFiles?.() // eslint-disable-line
      this.$nextTick(this.focusChatPrompt)

      return IssueComment.dispatch('$create', {
        exportRecordId,
        issueComment: { body: text },
      })
        .catch(() => {
          this.newMessage = { text }
        })
    },

    focusChatPrompt() {
      // eslint-disable-next-line
      this.$refs.chatPrompt?.focus?.()
    },

    startEdit(comment) {
      this.editCommentForm = {
        id: comment.id,
        originalText: comment.body,
        newText: comment.body,
      }
    },

    abortEdit() {
      this.editCommentForm = {
        id: null,
        originalText: '',
        newText: '',
      }
    },

    submitEdit(text, files) {
      const { exportRecordId } = this

      if (!exportRecordId) throw new Error('No selected export record')
      if (
        files.length &&
        !confirm(this.$t('issue.FileUploadNotImplementedSendQ'))
      ) return Promise.resolve(undefined)

      // reset chat form
      const preservedForm = this.editCommentForm
      this.abortEdit()
      this.$refs.chatPrompt?.resetFiles?.() // eslint-disable-line
      this.$nextTick(this.focusChatPrompt)

      if (preservedForm.newText === preservedForm.originalText) return

      return IssueComment.dispatch('$update', {
        exportRecordId,
        issueComment: { id: preservedForm.id, body: text },
      })
        .catch(() => {
          this.editCommentForm = preservedForm
        })
    },
  },
}
</script>

<style lang="sass" scoped>
.IssueComments
  display: flex
  flex-direction: column
  position: relative
  max-height: 100%

  &__tabs
    flex: 0 0 auto
    margin: 0 28px
    max-width: calc(100% - 28px * 2)

  &__content
    flex: 1
    display: flex
    flex-direction: column
    overflow: hidden

  &__record-toolbar
    margin-top: 24px
    margin-bottom: 8px
    padding: 0 28px
    display: flex
    height: 40px
    align-items: center
    font-weight: 500
    font-size: 24px
    line-height: 32px
    letter-spacing: 0.005em

  &__title
    font-weight: 500
    font-size: 20px
    line-height: 32px
    letter-spacing: 0.005em
    margin-right: 8px
    overflow: hidden
    text-overflow: ellipsis

  &__caption
    font-size: 16px
    line-height: 21px
    letter-spacing: 0.005em
    color: #A09EB9
    margin-right: 8px

  &__text
    letter-spacing: 0.005em
    font-size: 16px
    line-height: 21px

  &__record-selector
    padding: 18px 28px

  &__record-card
    padding: 16px
    border: 1px solid #E3E8F6
    border-color: #E3E8F6 !important
    border-radius: 4px
    cursor: pointer
    display: flex
    align-items: center
    white-space: nowrap

    & + &
      margin-top: 18px

  &__messages
    flex: 1

  &__error
    position: absolute
    bottom: 76px
    left: 0
    right: 0
    background: rgba(255, 80, 101, .8)
    color: white
    padding: 0 16px
    display: flex
    align-items: center
    justify-content: space-between
    z-index: 2

  &__prompt
    flex: 0 0 76px
</style>
