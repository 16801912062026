import { addEventListenerBeforeDestroy } from '../helpers'

const getEl = (vm, methodName) =>
  methodName !== null
    ? vm[methodName]()
    : vm.$el

// element will preserve its `scrollTop` position during `$route` changes
// NB! element should persist during `$route` change, but may be hidden or
// removed from DOM (`v-show`, <keep-alive />)
export default ({
  requires: {
    methods: {
      getScrollableElement = null, // defaults to `vm`.`$el`
    } = {},
  } = {},

  provides: {
    data: {
      scrollTop: scrollTopDataProp = 'scrollTop',
    } = {},

    events: {
      scroll: scrollEvent = 'scroll',
    } = {},
  } = {},

} = {}) => ({
  data: () => ({
    [scrollTopDataProp]: 0,
  }),

  watch: {
    // element restores its scroll position on route change
    // (works if component can persist during route change)
    // see also `IssueListView.beforeRouteLeave` and `<keep-alive />` in `DefaultLayout`
    $route: {
      async handler() {
        await this.$nextTick() // await new DOM

        const scrollableEl = getEl(this, getScrollableElement)
        if (scrollableEl && scrollableEl.scroll) {
          scrollableEl.scroll(0, this[scrollTopDataProp])
        }
      },
      immediate: true,
    },

    // component emits @scroll event
    [scrollTopDataProp]: {
      handler(scrollTop) {
        this.$emit(scrollEvent, scrollTop)
      },
    },
  },

  // on mounted scroll position of the element is stored to `vm`.`scrollTop`
  mounted() {
    const scrollableEl = getEl(this, getScrollableElement)
    if (scrollableEl) { // can be missing during tests + shallowMount
      addEventListenerBeforeDestroy(this, scrollableEl, 'scroll', e => {
        // save table's `scrollTop` to restore it later
        this[scrollTopDataProp] = e.target.scrollTop || 0
      })
      this.$emit(scrollEvent, 0)
    }
  },
})
