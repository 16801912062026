<template>
  <div
    ref="scrollable"
    class="IssueCommentsMessages"
  >
    <div
      class="d-flex flex-column justify-end"
      style="min-height: 100%"
    >
      <IssueCommentsMessage
        v-for="comment in comments"
        :key="comment.id"
        :comment="comment"
        @edit="$emit('edit', { comment })"
      />
    </div>
  </div>
</template>

<script>
import IssueCommentsMessage from './IssueCommentsMessage'

export default {
  name: 'IssueCommentsMessages',

  components: {
    IssueCommentsMessage,
  },

  props: {
    comments: { type: Array, default: null },
  },

  watch: {
    'comments.length': {
      handler(length) {
        if (length) this.$nextTick(this.scrollToBottom)
      },
      immediate: true,
    },
  },

  methods: {
    scrollToBottom() {
      // eslint-disable-next-line
      this.$refs.scrollable?.scroll?.(0, Number.MAX_SAFE_INTEGER)
    },
  },
}
</script>

<style lang="sass" scoped>
.IssueCommentsMessages
  padding: 28px 18px
  overflow: hidden auto
  scroll-behavior: smooth
</style>
