<template>
  <v-menu
    class="TeamMemberAssigneeRole"
    left
    nudge-right="8"
    nudge-top="8"
    :disabled="disabled"
  >
    <template #activator="{ attrs, on }">
      <v-btn
        class="TeamMemberAssigneeRole__activator"
        text
        x-small
        tile
        :height="32"
        color="#3C3A52"
        :disabled="disabled"
        :loading="loading"
        v-bind="attrs"
        v-on="{ ...on, ...$listeners }"
      >
        <span
          :class="{ 'pr-5': disabled }"
          v-text="$t(currentPermission.label)"
        />
        <v-icon
          v-show="!disabled"
          size="20"
          color="#8492A6"
          style="margin-top: 2px"
          v-text="'mdi-menu-down'"
        />
      </v-btn>
    </template>

    <v-list dense>
      <v-list-item
        v-for="perm in otherPermissions"
        :key="perm.value"
        :ripple="{ class: 'app-ripple' }"
        @click="perm.value !== currentPermission.value &&
          $emit('set-permission', perm)"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ $t(perm.label) }}
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action v-show="perm.value === currentPermission.value">
          <v-icon
            small
            v-text="'mdi-check'"
          />
        </v-list-item-action>
      </v-list-item>

      <v-list-item
        v-if="isShowRemove"
        key="remove"
        :ripple="{ class: 'error--text' }"
        @click="$emit('remove-assignee')"
      >
        <v-list-item-content>
          <v-list-item-title class="error--text">
            {{ $t('issue.removeAssignee') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import * as R from 'ramda'

import { ISSUE_PERMISSION_LEVEL as PERM } from '../constants'

export default {
  name: 'TeamMemberAssigneeRole',

  props: {
    permissionLevel: {
      type: String,
      required: true,
      validator: v => Object.values(PERM).map(level => level.value).includes(v),
    },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    isShowRemove: { type: Boolean, default: true },
  },

  data() {
    return {
      PERM,
    }
  },

  computed: {
    currentPermission() { return PERM[this.permissionLevel] },

    otherPermissions() {
      const { currentPermission: { value: roleValue } } = this
      const currentFirst = R.sortWith([
        R.descend(perm => perm.value === roleValue),
        R.ascend(perm => perm.label),
      ])
      return currentFirst([
        PERM.EDIT,
        PERM.READ,
      ])
    },
  },
}
</script>

<style lang="sass">
.TeamMemberAssigneeRole
  &__activator
    font-size: 13px !important
    font-weight: normal !important

    &.theme--light.v-btn.v-btn--disabled
      color: rgba(56, 54, 77, .6) !important
</style>
