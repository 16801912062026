<template>
  <div class="InlineIssueSummary">
    <div class="InlineIssueSummary__status-item">
      <div
        class="mr-2 text-truncate"
        v-text="getFieldDisplayName('status')"
      />
      <IssueStatus
        :project-id="issue.projectID"
        :project-name="project && project.name"
        :value="issue.status"
        :disable-management="!canManageStatuses"
        :disable-global-management="!isAdmin"
        :sync-status="issue.syncStatus || null"
        :editable="canEditIssue"
        platform-name="frigate"
        class="InlineIssueSummary__status"
        @input="changeStatus(issue, $event)"
        @mousedown.stop
      />
    </div>

    <div class="InlineIssueSummary__status-item ml-4">
      <div
        class="mr-2 text-truncate"
        v-text="getFieldDisplayName('totalScore')"
      />
      <ColorScore
        style="flex: 0 1 auto"
        :score="issue.totalScore"
        :info-label="getOptionDisplay('totalScore', SCORE_INFO)"
        :low-label="getOptionDisplay('totalScore', SCORE_LOW)"
        :medium-label="getOptionDisplay('totalScore', SCORE_MEDIUM)"
        :high-label="getOptionDisplay('totalScore', SCORE_HIGH)"
        :critical-label="getOptionDisplay('totalScore', SCORE_CRITICAL)"
        dense
      />
    </div>

    <DotScore
      class="InlineIssueSummary__status-item ml-3"
      :score="issue.probabilityScore"
      :label="getFieldDisplayName('probabilityScore')"
      :info-label="getOptionDisplay('probabilityScore', SCORE_INFO)"
      :low-label="getOptionDisplay('probabilityScore', SCORE_LOW)"
      :medium-label="getOptionDisplay('probabilityScore', SCORE_MEDIUM)"
      :high-label="getOptionDisplay('probabilityScore', SCORE_HIGH)"
      :critical-label="getOptionDisplay('probabilityScore', SCORE_CRITICAL)"
    />

    <DotScore
      class="InlineIssueSummary__status-item ml-5"
      :score="issue.criticalityScore"
      :label="getFieldDisplayName('criticalityScore')"
      :info-label="getOptionDisplay('criticalityScore', SCORE_INFO)"
      :low-label="getOptionDisplay('criticalityScore', SCORE_LOW)"
      :medium-label="getOptionDisplay('criticalityScore', SCORE_MEDIUM)"
      :high-label="getOptionDisplay('criticalityScore', SCORE_HIGH)"
      :critical-label="getOptionDisplay('criticalityScore', SCORE_CRITICAL)"
    />

    <div class="InlineIssueSummary__status-item ml-5">
      <SlaBadge
        v-if="slaConfig != null"
        :issue-id="issue.id"
        :sla-date="issue.sla"
        :completed-datetime="issue.completed"
        :start-datetime="issue.created"
        :manually-overriden="issue.slaManualSet != null"
        :editable="canEditIssue"
        @mousedown.stop
        @click.prevent
      />
    </div>
  </div>
</template>

<script>
import { IssueStatus } from '@hexway/shared-front'

import { SCORE_INFO, SCORE_LOW, SCORE_MEDIUM, SCORE_HIGH, SCORE_CRITICAL, PROJECT_PERMISSION_LEVEL } from '../constants'
import { issueRouteFromIssueList } from '../helpers'

import Issue from '../store/orm/issue'
import Project from '../store/orm/project'
import SlaConfig from '../store/orm/slaConfig'

import ColorScore from './ColorScore'
import DotScore from './DotScore'
import SlaBadge from './SlaBadge'

export default {
  name: 'InlineIssueSummary',

  components: {
    ColorScore,
    DotScore,
    IssueStatus,
    SlaBadge,
  },

  props: {
    projectId: { type: String, default: null },
    issue: { type: Object, required: true },
    cardId: { type: Number, default: null },
  },

  data() {
    return {
      SCORE_INFO,
      SCORE_LOW,
      SCORE_MEDIUM,
      SCORE_HIGH,
      SCORE_CRITICAL,
    }
  },

  computed: {
    project() { return Project.find(this.issue.projectID) },
    currentUser() { return this.$store.getters['user/current'] },

    slaConfig() {
      const { issue: { projectID: projectId } } = this
      return SlaConfig.query().withAll().find(projectId)
    },

    fieldsLookup() {
      const { $store, issue: { projectID: projectId } } = this
      return $store.getters['issueSchema/fieldsLookup'](projectId)
    },

    isAdmin() { return this.currentUser?.isAdmin },
    canManageStatuses() {
      const { project, isAdmin } = this
      return isAdmin || PROJECT_PERMISSION_LEVEL.OWNER.value === project?.permission
    },
    canEditIssue() { return this.issue.canBeEditedByCurrentUser() },

    detailsRoute() {
      const {
        projectId,
        $route: { query },
        cardId,
        issue: { id: issueId },
      } = this
      return issueRouteFromIssueList({ query, projectId, cardId, issueId })
    },
  },

  methods: {
    getFieldDisplayName(fieldName) {
      return this.fieldsLookup?.[fieldName]?.displayName
    },

    getOptionDisplay(fieldName, optionValue) {
      return this
        .fieldsLookup?.[fieldName]
        ?.allowedValues
        ?.find?.(opt => opt.value === optionValue)
        ?.displayValue
    },

    changeStatus(issue, newStatus) {
      const { issue: { projectID: projectId } } = this
      Issue.dispatch('$update', {
        projectId,
        issue: {
          id: issue.id,
          status: newStatus,
        },
      })
    },
  },
}
</script>

<style lang="sass" scoped>
  .InlineIssueSummary
    border-bottom: 1px solid #f0f1f3
    display: flex
    flex-wrap: nowrap

    &__status-item
      display: inline-flex
      align-items: center
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      font-size: 14px
      line-height: 20px
      letter-spacing: 0.005em
      color: #A09EB9

    &__status-text
      overflow: hidden
      text-overflow: ellipsis
</style>
