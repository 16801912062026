<!-- displays severity/score (i.e. of an issue) as a colored dot:
  `score` = 2 -> red dot
  `score` = 1 -> orange dot
  `score` = 0 -> green dot

  colors are configurable through props -->

<template>
  <span
    class="DotScore"
    v-on="$listeners"
  >
    <v-tooltip
      v-bind="tooltipProps"
      :disabled="!!inactive"
    >
      <template #activator="{ on, attrs }">
        <span
          class="DotScore__activator"
          v-bind="attrs"
          v-on="on"
        >
          <span
            class="DotScore__label mr-auto"
            v-text="label"
          />
          <v-avatar
            class="DotScore__dot"
            :size="8"
            :color="color"
          />
        </span>
      </template>

      <span v-text="tooltipText" />
    </v-tooltip>
  </span>
</template>

<script>
import { SCORE, SCORE_LOOKUP, SCORE_INFO, SCORE_LOW, SCORE_MEDIUM, SCORE_HIGH, SCORE_CRITICAL } from '../constants'
import i18n from '../i18n'

const INFO = SCORE_LOOKUP[SCORE_INFO]
const LOW = SCORE_LOOKUP[SCORE_LOW]
const MEDIUM = SCORE_LOOKUP[SCORE_MEDIUM]
const HIGH = SCORE_LOOKUP[SCORE_HIGH]
const CRITICAL = SCORE_LOOKUP[SCORE_CRITICAL]

export default {
  name: 'DotScore',

  props: {
    score: {
      type: Number,
      default: null,
      validator: score =>
        SCORE.map(s => s.value).includes(score),
    },

    label: { type: String, default: 'Score' },
    tooltip: { type: String, default: null }, // default: `${label}: ${scoreDisplayValue}`
    tooltipProps: { type: Object, default: () => ({ bottom: true }) },

    infoColor: { type: String, default: INFO.color },
    infoLabel: { type: String, default: i18n.t(INFO.displayValue) },
    lowColor: { type: String, default: LOW.color },
    lowLabel: { type: String, default: i18n.t(LOW.displayValue) },
    mediumColor: { type: String, default: MEDIUM.color },
    mediumLabel: { type: String, default: i18n.t(MEDIUM.displayValue) },
    highColor: { type: String, default: HIGH.color },
    highLabel: { type: String, default: i18n.t(HIGH.displayValue) },
    criticalColor: { type: String, default: CRITICAL.color },
    criticalLabel: { type: String, default: i18n.t(CRITICAL.displayValue) },

    inactive: { type: Boolean, default: false },
    inactiveColor: { type: String, default: '#3C3A52' },
  },

  computed: {
    color() {
      const {
        inactive, inactiveColor, score,
        infoColor, lowColor, mediumColor, highColor, criticalColor,
      } = this

      if (inactive) return inactiveColor

      return {
        0: infoColor,
        1: lowColor,
        2: mediumColor,
        3: highColor,
        4: criticalColor,
      }[score] ?? 'grey'
    },

    tooltipText() {
      if (this.tooltip) return this.tooltip

      const { label, infoLabel, lowLabel, mediumLabel, highLabel, criticalLabel, score } = this
      const scoreLabel = {
        0: infoLabel,
        1: lowLabel,
        2: mediumLabel,
        3: highLabel,
        4: criticalLabel,
      }[score] ?? this.$t('dashboard.Unknown')
      return `${label}: ${scoreLabel}`
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/variables'

.DotScore
  overflow: hidden

  &__activator
    display: inline-flex
    align-items: center
    overflow: hidden
    width: 100%

  &__label
    font-size: 14px
    line-height: 20px
    letter-spacing: 0.005em
    color: #A09EB9
    flex: 0 1 auto
    overflow: hidden
    text-overflow: ellipsis

  &__dot
    margin-left: 8px
    flex: 0 0 auto
</style>
