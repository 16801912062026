<template>
  <v-list-item
    class="TeamMember"
    :ripple="ripple"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list-item-avatar class="my-0">
      <UserAvatar
        :user="user"
        size="lg"
        :owner-star="isOwner"
      />
    </v-list-item-avatar>

    <v-list-item-content class="py-0">
      <v-list-item-title>
        {{ user.firstName }} {{ user.lastName }}
      </v-list-item-title>
      <v-list-item-subtitle class="text--secondary">
        {{ user.userLogin }} {{ user.userEmail }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <slot name="perm" />
    <slot name="append" />
  </v-list-item>
</template>

<script>
import { PROJECT_PERMISSION_LEVEL as PERM } from '../constants'

import UserAvatar from './UserAvatar'

export default {
  name: 'TeamMember',

  components: {
    UserAvatar,
  },

  inheritAttrs: false,

  props: {
    user: { type: Object, required: true },
    level: { type: String, default: null },
  },

  computed: {
    ripple() {
      const { ripple, to, href, link } = this.$attrs
      const { click: onClick } = this.$listeners
      if (ripple) return ripple
      if (link || to || href || onClick) return { class: 'app-ripple' }
      return false
    },

    isOwner() { return this.level === PERM.OWNER.value },
  },
}
</script>

<style lang="sass" scoped>
.TeamMember
  padding-left: 48px
  padding-right: 37px
  height: 56px
</style>
