<template>
  <CommonDataTable
    ref="table"
    :value="value"
    class="IssueTableView"
    :headers="headers"
    :items="clientFilteredIssues"
    :items-per-page="-1"
    item-key="id"
    :show-select="projectId != null"
    fixed-header
    resizeable-columns
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    dense
    plain-styles
    scrollable-height="calc(100vh - 64px)"
    local-storage-key="IssueTableView"
    :row-to="issue => issue.getInlineRoute($route)"
    row-replace
    row-exact
    multi-sort
    show-actions-row
    :row-background="(issue, { selected }) => selected
      ? 'rgba(230, 230, 255, .3)'
      : issue.id === openIssueId
        ? '#EDF0FA'
        : null"
    :custom-row-height="64"
    @input="$emit('input', $event)"
    @current-items="storeIssueOrder"
    @click:row="$store.commit('appDrawer/setMiniDrawer', true)"
  >
    <template #actions>
      <v-btn
        v-if="projectId != null"
        text
        plain
        color="primary"
        dense
        class="IssueTableView__action ml-2"
        :disabled="!value.length"
        @click="$emit('mass-assign')"
      >
        <v-icon
          class="mr-1"
          small
          v-text="'mdi-account-circle-outline'"
        />
        <span v-text="$t('issue.Assign')" />
      </v-btn>

      <CommonTextField
        v-model="searchQuery"
        outlined
        dense
        hide-details
        margins-with-hidden-details="mb-0"
        class="mx-4"
        append-icon="mdi-magnify"
        placeholder="Search"
      />

      <CommonDataTableColumnSelector
        class="ml-auto mr-2"
        @input="displayedColumns = $event"
        @pin="pinnedColumns = $event"
      />
    </template>

    <template #item.status="{ item: issue, value: status }">
      <IssueStatus
        :project-id="issue.projectID"
        :project-name="issue.project && issue.project.name"
        :value="status"
        :disable-management="!canManageStatuses(issue)"
        :disable-global-management="!isAdmin"
        :editable="issue.canBeEditedByCurrentUser()"
        platform-name="frigate"
        class="IssueTable__status flex-shrink-1 overflow-hidden"
        style="text-overflow: initial"
        @input="changeStatus(issue, $event)"
        @mousedown.stop
        @click.native.capture.prevent
      />
    </template>

    <template #item.totalScore="{ value: totalScore }">
      <ColorScore
        :score="totalScore"
        dense
        :mini="$vuetify.breakpoint.mdAndDown"
        :info-label="getOptionDisplay('totalScore', SCORE_INFO)"
        :low-label="getOptionDisplay('totalScore', SCORE_LOW)"
        :medium-label="getOptionDisplay('totalScore', SCORE_MEDIUM)"
        :high-label="getOptionDisplay('totalScore', SCORE_HIGH)"
        :critical-label="getOptionDisplay('totalScore', SCORE_CRITICAL)"
      />
    </template>

    <template #item.probabilityScore="{ value: probabilityScore }">
      <TriScore :score="probabilityScore" />
    </template>

    <template #item.criticalityScore="{ value: criticalityScore }">
      <TriScore :score="criticalityScore" />
    </template>

    <template #item.sla="{ item: issue }">
      <SlaBadge
        v-if="issue.slaConfig != null"
        :issue-id="issue.id"
        :sla-date="issue.sla"
        :completed-datetime="issue.completed"
        :start-datetime="issue.created"
        :manually-overriden="issue.slaManualSet != null"
        :editable="issue.canBeEditedByCurrentUser()"
        @mousedown.stop
        @click.capture.prevent
      />
    </template>

    <template #item.ips="{ value: ips }">
      <div
        style="margin-top: auto; margin-bottom: auto; text-align: left"
        class="text-wrap"
        @mousedown.stop
        @touchstart.stop
        @click.prevent
      >
        <template v-for="(ip, ix) in ips">
          <span :key="[ip, ix].join(':')">
            <router-link
              class="IssueTableView__link"
              :to="{ query: { ...$route.query, ips: ip, issueId: undefined } }"
              replace
              v-text="ip"
          />{{ ix < (ips.length - 1) ? ', ' : '' }}<!-- eslint-disable-line -->
          </span>
        </template>
      </div>
    </template>

    <template #item.hostnames="{ value: hostnames }">
      <div
        style="margin-top: auto; margin-bottom: auto; text-align: left"
        class="text-wrap"
        @mousedown.stop
        @touchstart.stop
        @click.prevent
      >
        <template v-for="(hostname, ix) in hostnames">
          <span :key="[hostname, ix].join(':')">
            <router-link
              class="IssueTableView__link"
              :to="{ query: { ...$route.query, hostnames: hostname, issueId: undefined } }"
              replace
              v-text="hostname"
          />{{ ix < (hostnames.length - 1) ? ', ' : '' }}<!-- eslint-disable-line -->
          </span>
        </template>
      </div>
    </template>

    <template #item.ports="{ value: ports }">
      <div
        style="margin-top: auto; margin-bottom: auto; text-align: left"
        class="text-wrap"
        @mousedown.stop
        @touchstart.stop
        @click.prevent
      >
        <template v-for="(port, ix) in ports">
          <span :key="[port, ix].join(':')">
            <router-link
              class="IssueTableView__link"
              :to="{ query: { ...$route.query, ports: port, issueId: undefined } }"
              replace
              v-text="port"
            />{{ ix < (ports.length - 1) ? ', ' : '' }}<!-- eslint-disable-line -->
          </span>
        </template>
      </div>
    </template>

    <template #item.paths="{ value: paths }">
      <div
        style="margin-top: auto; margin-bottom: auto; text-align: left"
        class="text-wrap"
        @mousedown.stop
        @touchstart.stop
        @click.prevent
      >
        <template v-for="(path, ix) in paths">
          <span :key="[path, ix].join(':')">
            <router-link
              class="IssueTableView__link"
              :to="{ query: { ...$route.query, paths: path, issueId: undefined } }"
              replace
              v-text="path"
            />{{ ix < (paths.length - 1) ? ', ' : '' }}<!-- eslint-disable-line -->
          </span>
        </template>
      </div>
    </template>

    <template #item.optionals="{ value: optionals }">
      <div
        style="margin-top: auto; margin-bottom: auto; text-align: left"
        class="text-wrap"
        @mousedown.stop
        @touchstart.stop
        @click.prevent
      >
        <template v-for="(optional, ix) in optionals">
          <span :key="[optional, ix].join(':')">
            <router-link
              class="IssueTableView__link"
              :to="{ query: { ...$route.query, optionals: optional, issueId: undefined } }"
              replace
              v-text="optional"
            />{{ ix < (optionals.length - 1) ? ', ' : '' }}<!-- eslint-disable-line -->
          </span>
        </template>
      </div>
    </template>

    <template #item.protocols="{ value: protocols }">
      <div
        style="margin-top: auto; margin-bottom: auto; text-align: left"
        class="text-wrap"
        @mousedown.stop
        @touchstart.stop
        @click.prevent
      >
        <template v-for="(protocol, ix) in protocols">
          <span :key="[protocol, ix].join(':')">
            <router-link
              class="IssueTableView__link"
              :to="{ query: { ...$route.query, protocols: protocol, issueId: undefined } }"
              replace
              v-text="protocol"
            />{{ ix < (protocols.length - 1) ? ', ' : '' }}<!-- eslint-disable-line -->
          </span>
        </template>
      </div>
    </template>

    <template #item.assignee="{ item: issue, hover }">
      <IssueAssigneeButton
        v-if="projectId != null"
        class="mx-n6 flex-grow-1"
        :issue="issue"
        :style="{ opacity: hover ? '1' : '0' }"
        @click.stop.prevent="$emit('assign-from', { issue })"
      />
    </template>

    <template #item.task="{ item: issue, hover }">
      <IssueTaskButton
        v-if="projectId != null"
        activator-class="mx-n6 flex-grow-1"
        :project-id="issue.projectID"
        :issue="issue"
        :style="{ opacity: hover ? '1' : '0' }"
        attach-to-parent
      />
    </template>

    <template
      v-if="false"
      #append
    >
      <AppPagination
        class="IssueTableView__footer"
        :value="dbgPage"
        :length="666"
        @input="dbgPage = $event"
      />
    </template>
  </CommonDataTable>
</template>

<script>
import { IssueStatus, helpers as H } from '@hexway/shared-front'
import * as R from 'ramda'
import { Ripple } from 'vuetify/lib/directives'

import bus from '../bus'
import {
  PROJECT_PERMISSION_LEVEL,
  SCORE_INFO,
  SCORE_LOW,
  SCORE_MEDIUM,
  SCORE_HIGH,
  SCORE_CRITICAL,
  ASSET_VERSION,
} from '../constants'
import { listenBeforeDestroy, animationFrame, fmtDt } from '../helpers'
import preserveScroll from '../mixins/preserveScroll'

import Issue from '../store/orm/issue'
import Project from '../store/orm/project'

import ColorScore from './ColorScore'
import IssueAssigneeButton from './IssueAssigneeButton'
import IssueTaskButton from './IssueTaskButton'
import SlaBadge from './SlaBadge'
import TriScore from './TriScore'

export default {
  name: 'IssueTableView',

  components: {
    // DynamicScroller,
    // DynamicScrollerItem,

    ColorScore,
    IssueAssigneeButton,
    IssueStatus,
    IssueTaskButton,
    SlaBadge,
    TriScore,
  },

  directives: {
    Ripple, // required for v-simple-checkbox
  },

  mixins: [
    preserveScroll({
      requires: {
        methods: { getScrollableElement: 'getScrollableEl' },
      },
    }),
  ],

  props: {
    projectId: { type: String, default: null },

    // see `IssueSchema`; `store/schema:Issue`
    issues: { type: Array, required: true },

    // provided `cardId` changes links to the issues
    cardId: { type: Number, default: null },

    // to style active issue
    openIssueId: { type: String, default: null },

    // list of selected rows for v-model
    value: { type: Array, default: () => [] },
  },

  data() {
    return {
      SCORE_INFO,
      SCORE_LOW,
      SCORE_MEDIUM,
      SCORE_HIGH,
      SCORE_CRITICAL,

      dbgPage: 42, // fixme
      scrollBarHeight: 0,
      searchQuery: '',

      hiddenColumns: H.keepInStorage(this, 'hiddenColumns', [], { prefix: 'IssueTableView', silent: true }),
      columnOrder: H.keepInStorage(this, 'columnOrder', [], { prefix: 'IssueTableView', silent: true }),
      pinnedColumns: H.keepInStorage(this, 'pinnedColumns', [], { prefix: 'IssueTableView', silent: true }),
      // e.g.: ['title', 'sla']
      sortBy: H.keepInStorage(this, 'sortBy', ['status'], { prefix: 'IssueTableView', silent: true }),
      // e.g.: [true, false]
      sortDesc: H.keepInStorage(this, 'sortDesc', [false], { prefix: 'IssueTableView', silent: true }),
    }
  },

  computed: {
    currentUser() { return this.$store.getters['user/current'] },
    isAdmin() { return this.currentUser?.isAdmin },
    issueSchema() { return this.$store.getters['issueSchema/get'](this.projectId) },

    fieldsLookup() {
      const { $store, projectId } = this
      return projectId && $store.getters['issueSchema/fieldsLookup'](projectId)
    },

    searchTokens() {
      return (this.searchQuery || '')
        .trim()
        .split(/\s+?/g)
        .map(s => s.trim().toLocaleLowerCase())
        .filter(Boolean)
    },

    clientFilteredIssues() {
      const { searchTokens, issues } = this
      if (!searchTokens.length) return issues
      return issues && issues.filter(({ _searchBy }) =>
        searchTokens.every(q => _searchBy.includes(q)))
    },

    displayedColumns: {
      get() {
        return this.headers
          .map(h => h.value)
          .filter(v => !this.hiddenColumns.includes(v))
      },
      set({ selectedColumns, order }) {
        selectedColumns = selectedColumns || []
        this.columnOrder = order
        this.hiddenColumns = this.headers
          .map(h => h.value)
          .filter(v => !selectedColumns.includes(v))
      },
    },

    headers() {
      const { hiddenColumns, pinnedColumns, columnOrder, issueSchema, projectId } = this
      const { mdAndDown } = this.$vuetify.breakpoint

      const headers = [
        this.projectId == null &&
          { text: this.$t('issue.TableProject'), value: 'projectName', width: 128 },
        { text: this.$t('issue.TableStatus'), value: 'status', width: 128 },
        {
          text: mdAndDown ? this.$t('issue.TableScoreShort') : this.$t('issue.TableScore'),
          value: 'totalScore',
          width: mdAndDown ? 68 : 94,
        },

        !mdAndDown && {
          text: this.truncate(
            this.getFieldDisplayName('probabilityScore') || this.$t('issue.ProbabilityShort'),
            4,
            '.',
          ),
          value: 'probabilityScore',
          width: 90,
        },
        !mdAndDown && {
          text: this.truncate(
            this.getFieldDisplayName('criticalityScore') || this.$t('issue.CriticalityShort'),
            4,
            '.',
          ),
          value: 'criticalityScore',
          width: 90,
        },

        { text: this.$t('issue.TableIssue'), value: 'name', width: 200 },
        {
          text: this.$t('issue.Created'),
          value: 'created',
          width: 100,
          displayValue: issue => issue.created && fmtDt(issue.created),
          tooltip: issue => issue.created,
        },
        {
          text: this.$t('issue.TableSla'),
          value: 'sla',
          width: 100,
        },
        {
          text: this.getFieldDisplayName('ips') || this.$t('issue.TableIps'),
          value: 'ips',
          displayValue: issue => issue.ips.join(', '),
          width: 145,
        },

        !mdAndDown && {
          text: this.getFieldDisplayName('hostnames') || this.$t('issue.TableHostnames'),
          value: 'hostnames',
          displayValue: issue => issue.hostnames.join(', '),
          width: 175,
        },

        ((!projectId && !issueSchema) || issueSchema?.assetVersion === ASSET_VERSION.COMPLEX) && {
          text: this.$t('issue.TablePorts'),
          value: 'ports',
          displayValue: issue => issue.ports?.join(', '),
          width: 175,
        },

        ((!projectId && !issueSchema) || issueSchema?.assetVersion === ASSET_VERSION.COMPLEX) && {
          text: this.$t('issue.TablePaths'),
          value: 'paths',
          displayValue: issue => issue.paths?.join(', '),
          width: 175,
        },

        ((!projectId && !issueSchema) || issueSchema?.assetVersion === ASSET_VERSION.COMPLEX) && {
          text: this.$t('issue.TableOptionals'),
          value: 'optionals',
          displayValue: issue => issue.optionals?.join(', '),
          width: 175,
        },

        ((!projectId && !issueSchema) || issueSchema?.assetVersion === ASSET_VERSION.COMPLEX) && {
          text: this.$t('issue.TableProtocols'),
          value: 'protocols',
          displayValue: issue => issue.protocols?.join(', '),
          width: 175,
        },

        this.projectId != null && {
          text: this.$t('issue.TableAssignee'),
          value: 'assignee',
          width: 125,
          align: 'center',
          sortable: false,
        },
        this.projectId != null && {
          text: this.$t('issue.TableTask'),
          value: 'task',
          width: 125,
          align: 'center',
          sortable: false,
        },
      ]
        .filter(Boolean)
        .map(header =>
          hiddenColumns.includes(header.value)
            ? ({ ...header, render: false })
            : header)
        .map(header =>
          pinnedColumns.includes(header.value)
            ? ({ ...header, pin: true })
            : header)
      return R.sortBy(header => columnOrder.indexOf(header.value), headers)
    },

    listOrder() { return this.$store.state.entities.issue.$visibleOrder },

    issueOrder() {
      const { issues, listOrder } = this
      return R.equals([...listOrder].sort(), issues.map(R.prop('id')).sort())
        ? listOrder
        : (issues && issues.map(R.prop('id')))
    },
  },

  mounted() {
    // on mounted we may want to scroll to the issue
    if (this.openIssueId) this.scrollToOpenIssue()
    listenBeforeDestroy(this, bus, 'inlineIssue:navigation', async () => {
      await this.$nextTick()
      await this.scrollToOpenIssue()
    })
    this.saveScrollbarHeight()
  },

  updated() {
    this.saveScrollbarHeight()
  },

  methods: {
    truncate(s, length, omission = '…') {
      return R.when(
        R.propSatisfies(R.gt(R.__, length), 'length'),
        R.pipe(R.take(length), R.concat(R.__, omission)),
        s,
      )
    },

    getOptionDisplay(fieldName, optionValue) {
      return this
        .fieldsLookup?.[fieldName]
        ?.allowedValues
        ?.find?.(opt => opt.value === optionValue)
        ?.displayValue
    },

    getScrollableEl() {
      return this.$refs.issues || null
    },

    async scrollToOpenIssue() {
      const { openIssueId: id, issueOrder } = this

      const issueIx = issueOrder.indexOf(id)
      if (issueIx === -1) {
        throw new Error(`No issue with id=${id} to scroll to`)
      }
      await this.$nextTick()

      const el = this.getScrollableEl()
        .querySelector(`[data-index="${issueIx}"]`)
      el.scrollIntoView({ block: 'nearest' })
      el.focus()
      await animationFrame()
      el.focus()
    },

    storeIssueOrder(issues) {
      const order = R.pipe(
        R.defaultTo([]),
        R.map(R.prop('id')),
      )(issues)
      Issue.commit(state => { state.$visibleOrder = order })
    },

    getFieldDisplayName(fieldName) {
      if (this.projectId) return this.fieldsLookup?.[fieldName]?.displayName
      return null
    },

    saveScrollbarHeight() {
      const tableWrapper = this.$el?.querySelector?.('.v-data-table__wrapper')
      const height =
        (tableWrapper?.offsetHeight ?? 0) -
        (tableWrapper?.clientHeight ?? 0)
      if (height !== this.scrollBarHeight) this.scrollBarHeight = height
    },

    canManageStatuses(issue) {
      const { isAdmin } = this
      const project = issue.project || Project.find(issue.projectID)
      return isAdmin || PROJECT_PERMISSION_LEVEL.OWNER.value === project?.permission
    },

    changeStatus(issue, newStatus) {
      Issue.dispatch('$update', {
        projectId: issue.projectID,
        issue: {
          id: issue.id,
          status: newStatus,
        },
      })
    },
  },
}
</script>

<style lang="sass">
@import '../scss/variables'
@import '../scss/mixins'

$footer-height: 48px

.IssueTableView
  &__action
    font-size: 14px !important

  &__link
    color: inherit !important
    text-decoration: inherit !important

    &:hover, &:active, &:focus
      color: var(--v-primary-base) !important

  &__footer
    background: white
    height: $footer-height
    overflow: hidden
    display: flex
    justify-content: center
    align-items: center

  &__caption
    color: #8B90A0
</style>
