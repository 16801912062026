<template>
  <ChatSimpleMessage
    class="IssueCommentsMessage"
    :user="author"
    :avatar-props="{
      icon: comment.avatarIcon,
      color: comment.avatarColor,
      href: comment.externalHref,
      target: comment.externalHref && '_blank',
    }"
    :text="comment.body"
    :datetime="comment.created"
    :ripple="ripple"
    format="JIRA"
    :state="inPurgatory ? CHAT_MESSAGE_STATUS.clientPurgatory : CHAT_MESSAGE_STATUS.delivered"
    :note="inPurgatory ? 'deleted' : comment.updated ? 'edited' : null"
    :note-color="inPurgatory ? 'error' : null"
    :note-title="comment.updated ? ('Edited ' + fmtDt(comment.updated)) : null"
    :semi-transparent="inPurgatory"
  >
    <template
      v-if="currentUserId === comment.localUserID"
      #actions="{ color }"
    >
      <CommonIconButton
        icon="mdi-pencil-outline"
        :ripple="{ class: 'app-ripple' }"
        :color="color"
        small
        @click="$emit('edit')"
      />

      <v-spacer />

      <CommonIconButton
        icon="mdi-trash-can-outline"
        :ripple="{ class: 'app-ripple' }"
        :color="color"
        small
        @click="scheduleDelete"
      />
    </template>
  </ChatSimpleMessage>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import md5 from 'md5'

import { CHAT_MESSAGE_STATUS } from '../constants'
import { fmtDt } from '../helpers'

import IssueComment from '../store/orm/issueComment'

import ChatSimpleMessage from './ChatSimpleMessage'

const DELETE_MESSAGE_TIMEOUT = 6000

const getUuid = (msg) => uuidv4({
  random: md5(msg).slice(0, 16).split('').map(s => s.charCodeAt(0)),
})

export default {
  name: 'IssueCommentsMessage',

  components: {
    ChatSimpleMessage,
  },

  props: {
    // see type `MessageToGet` and store module `issueChat`
    comment: { type: Object, required: true },
  },

  data() {
    return {
      CHAT_MESSAGE_STATUS,
      inPurgatory: false,
    }
  },

  computed: {
    currentUserId() {
      const currentUser = this.$store.getters['user/current']
      return currentUser && currentUser.id
    },

    ripple() {
      const { comment } = this
      if (comment.isLocal) return { class: 'white--text' }
      return { class: 'blue--text text--lighten-2' }
    },

    author() {
      const { $store, comment: { author, localUserID } } = this

      if (localUserID) {
        const localUser = $store.getters['user/get'](localUserID)
        if (localUser) return localUser
      }

      const [firstName, ...restOfName] = (author || '')
        .split(' ')
        .filter(Boolean)

      return {
        id: localUserID || getUuid(author),
        firstName: firstName || '',
        lastName: restOfName.join(' '),
        userLogin: this.$t('issue.Noname'),
      }
    },
  },

  methods: {
    fmtDt,

    scheduleDelete() {
      const { comment } = this

      const doDelete = () => IssueComment.dispatch('$delete', {
        exportRecordId: comment.exportRecordID,
        issueCommentId: comment.id,
      })
      const undo = () => {
        this.inPurgatory = false
        this.$store.commit('$snackbar/hide')
      }

      this.inPurgatory = true
      this.$store.commit('$snackbar/setMessage', {
        message: 'Comment will be deleted',
        timeout: DELETE_MESSAGE_TIMEOUT,
        persistent: true,
        onClose: () => {
          if (this.inPurgatory) doDelete()
        },
        bottom: 64,
        action: {
          type: 'function',
          label: 'Undo',
          fn: undo,
          once: true,
        },
      })
    },
  },
}
</script>
