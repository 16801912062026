<template>
  <div class="AssigneeDialogPermissions">
    <TeamMember
      v-for="userAndPerm in usersWithPermissions"
      :key="userAndPerm.id"
      :user="userAndPerm.user"
    >
      <template #append>
        <v-list-item-action>
          <TeamMemberAssigneeRole
            :permission-level="userAndPerm.level"
            :disabled="false"
            :loading="loading"
            @set-permission="$emit('set-permission', { user: userAndPerm.user, level: $event.value })"
            @remove-assignee="$emit('remove-assignee', { user: userAndPerm.user })"
          />
        </v-list-item-action>
      </template>
    </TeamMember>

    <v-list-item v-if="usersWithPermissions != null && usersWithPermissions.length === 0">
      <v-list-item-content class="pl-6">
        <v-list-item-title>{{ $t('issue.NoUsersAssigned') }}</v-list-item-title>
        <v-list-item-subtitle>
          {{ $t('issue.AddAssigneeM') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import TeamMember from './TeamMember'
import TeamMemberAssigneeRole from './TeamMemberAssigneeRole'

export default {
  name: 'AssigneeDialogPermissions',

  components: {
    TeamMember,
    TeamMemberAssigneeRole,
  },

  props: {
    // see `AssigneeDialog.vue:computed:usersWithPermissions`
    usersWithPermissions: { type: Array, required: true },

    loading: { type: Boolean, default: false },
  },
}
</script>
