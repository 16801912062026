<template>
  <v-btn
    class="IssueAssigneeButton"
    :class="{
      'IssueAssigneeButton--has-permissions':
        permissions != null && permissions.length > 0,
    }"
    text
    tile
    height="unset"
    :ripple="{ class: 'white--text' }"
    v-bind="$attrs"
    v-on="$listeners"
    @mousedown.stop
    @touchstart.stop
  >
    <template v-if="permissions && permissions.length">
      <UserAvatar
        class="IssueAssigneeButton__avatar"
        :user="permissions[0].user"
        :title="userDisplayName(permissions[0].user)"
      />
      <UserAvatar
        v-if="permissions.length === 2"
        class="IssueAssigneeButton__avatar"
        :user="permissions[1].user"
      />
      <v-avatar
        v-if="permissions.length > 2"
        class="IssueAssigneeButton__avatar white--text font-weight-regular"
        color="grey"
        :title="plusTitle"
        :size="32"
      >
        <span>+ {{ permissions.length - 1 }}</span>
      </v-avatar>
    </template>

    <template v-else>
      <v-icon
        key="icon"
        v-text="'mdi-account-circle-outline'"
      />
      <span
        key="label"
        class="ml-2"
        v-text="$t('issue.Assign')"
      />
    </template>
  </v-btn>
</template>

<script>
import UserAvatar from './UserAvatar'

export default {
  name: 'IssueAssigneeButton',

  components: {
    UserAvatar,
  },

  props: {
    issue: { type: Object, required: true },
  },

  computed: {
    permissions() {
      return this.$store.getters['permission/getProjectAssignees'](this.issue.id)
    },

    plusTitle() {
      const { permissions } = this
      return permissions && permissions
        .slice(1)
        .map(perm => perm.user)
        .map(this.userDisplayName)
        .join(', ')
    },
  },

  methods: {
    userDisplayName(user) {
      return [
        user.firstName,
        user.lastName,
        user.userLogin,
      ].filter(Boolean).join(' ')
    },
  },
}
</script>

<style lang="sass" scoped>
@import '../scss/variables'

.IssueAssigneeButton
  align-self: stretch
  opacity: 0
  transition: all .15s map-get($transition, 'swing')
  padding: 0 !important

  &:not(.v-btn--disabled)
    &:hover, &:active, &:focus
      opacity: .3

  &--has-permissions
    opacity: 1 !important

  &__avatar
    & + &
      margin-left: 6px
</style>
